<script lang="ts" setup>
import { DiscountTypeValues } from '@ankor-io/common/vessel/types'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import {
  InputAmountsTaxedValues,
  LineItemPricing,
  VesselPricing,
  VesselShowcaseOptions,
} from '@/sections/vessel-showcase/types/types'

type Props = {
  pricing: VesselPricing
  layoutOptions: VesselShowcaseOptions
}

const props = defineProps<Props>()

const pricingLineColumns = [
  {
    key: 'item',
    label: 'Item',
  },
  {
    key: 'conditions',
    label: 'Conditions',
  },
  {
    key: 'quantity',
    label: 'Quantity',
  },
  {
    key: 'unitPrice',
    label: 'Price',
  },
  {
    key: 'discount',
    label: 'Discount',
  },
  {
    key: 'taxRate',
    label: 'Tax Rate',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
]

const getLineItemAttribute = (lineItemPricing: LineItemPricing, attribute: string): string => {
  if (attribute === 'quantity') {
    const suffix = lineItemPricing.displayQuantityAsPercent ? '%' : ''
    const quantityToDisplay = lineItemPricing.displayQuantityAsPercent
      ? (lineItemPricing[attribute] || 0) * 100
      : lineItemPricing[attribute]
    return lineItemPricing[attribute] ? `${Math.round(quantityToDisplay * 100) / 100}${suffix}` : '-'
  }

  if (attribute === 'taxRate') {
    return lineItemPricing[attribute]
      ? `${lineItemPricing[attribute]?.label} ${
          Math.round((lineItemPricing[attribute]?.value || 0) * 100 * 100) / 100
        }%`
      : '-'
  }

  if (attribute === 'discount') {
    const value =
      lineItemPricing[attribute]?.type === DiscountTypeValues.AMOUNT
        ? lineItemPricing[attribute]?.value
        : Math.round((lineItemPricing[attribute]?.value || 0) * 100 * 100) / 100
    return lineItemPricing[attribute]
      ? `${value}${lineItemPricing[attribute]?.type === DiscountTypeValues.PERCENTAGE ? '%' : ''}`
      : '-'
  }

  if (attribute === 'amount' || attribute === 'unitPrice') {
    return (Number(lineItemPricing[attribute]) / 100).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  if (attribute === 'item' || attribute === 'conditions') {
    return lineItemPricing[attribute]?.toString() ?? ''
  }

  return ''
}
</script>
<template>
  <section>
    <h3 class="mt-4 font-bold text-3xl">
      <SingleLineTextEditor :value="props.layoutOptions.pricingLabel" :is-editable="false" />
    </h3>
    <div class="sm:rounded-lg">
      <div v-if="props.pricing.note" class="basis-2/4 text-left">
        <span class="text-sm">Note:</span>
        <MultiLineTextEditor :value="props.pricing.note" :is-editable="false" />
      </div>

      <div class="overflow-x-auto drop-shadow-sm sm:rounded-lg mx-auto mt-4 border border-gray-200">
        <table class="w-full text-sm text-gray-700 text-left whitespace-nowrap" aria-describedby="Pricing">
          <thead
            class="text-xs text-right uppercase"
            :style="{ background: props.layoutOptions.pricingHeaderBackground }"
          >
            <tr>
              <template v-for="(pricingLineColumn, index) of pricingLineColumns">
                <th
                  v-if="props.layoutOptions.enabledPricingColumns?.includes(pricingLineColumn.key)"
                  scope="col"
                  class="px-2 py-4"
                  :key="`${pricingLineColumn.key}-${index}`"
                  :class="[
                    { 'border-r border-gray-200': index !== pricingLineColumns.length - 1 },
                    { 'text-left': ['item', 'conditions', 'taxRate'].includes(pricingLineColumn.key) },
                  ]"
                >
                  <div class="flex items-center">
                    {{ pricingLineColumn.label }}
                  </div>
                </th>
              </template>
            </tr>
          </thead>
          <tbody class="tracking-wider text-right" :style="{ background: props.layoutOptions.pricingBodyBackground }">
            <template
              v-for="(lineItem, lineItemIndex) of props.pricing.lineItems"
              :key="`${lineItem.item}-${lineItemIndex}`"
            >
              <tr class="whitespace-nowrap border-t border-gray-200">
                <template v-for="(pricingLineColumn, index) of pricingLineColumns">
                  <td
                    v-if="props.layoutOptions.enabledPricingColumns?.includes(pricingLineColumn.key)"
                    class="ml-3 p-2 relative"
                    :key="`${pricingLineColumn.key}-${lineItemIndex}-${index}`"
                    :class="[
                      { 'border-r border-gray-200': index !== pricingLineColumns.length - 1 },
                      { 'text-left': ['item', 'conditions', 'taxRate'].includes(pricingLineColumn.key) },
                    ]"
                  >
                    <SingleLineTextEditor
                      v-if="['item', 'conditions'].includes(pricingLineColumn.key)"
                      :value="getLineItemAttribute(lineItem, pricingLineColumn.key)"
                      :is-editable="false"
                    />
                    <template v-else>
                      {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                    </template>
                  </td>
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div class="flex justify-end">
        <div class="grid gap-x-4 gap-y-1 mx-2 mt-2 grid-cols-2 text-right">
          <template v-if="props.pricing.inputAmountTaxed !== InputAmountsTaxedValues.NONE">
            <div class="opacity-60">Subtotal:</div>
            <div>
              <span class="font-bold">
                {{
                  props.pricing.subTotal
                    ? (props.pricing.subTotal / 100).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : 0
                }}
              </span>
              {{ props.pricing.currency }}
            </div>
          </template>

          <div v-if="props.pricing.inputAmountTaxed === InputAmountsTaxedValues.INCLUSIVE" class="opacity-60">
            Includes Tax:
          </div>
          <div v-if="props.pricing.inputAmountTaxed === InputAmountsTaxedValues.EXCLUSIVE" class="opacity-60">
            Total Tax:
          </div>
          <div v-if="props.pricing.inputAmountTaxed !== InputAmountsTaxedValues.NONE">
            <span class="font-bold">
              {{
                props.pricing.totalTax
                  ? (props.pricing.totalTax / 100).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0
              }}
            </span>
            {{ props.pricing.currency }}
          </div>

          <div class="opacity-60">Total:</div>
          <div :class="{ 'border-t': props.pricing.inputAmountTaxed !== InputAmountsTaxedValues.NONE }">
            <span class="font-bold">
              {{
                props.pricing.total
                  ? (props.pricing.total / 100).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0
              }}
            </span>
            {{ props.pricing.currency }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

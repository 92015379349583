<script setup lang="ts">
import { Ref, onMounted, ref } from 'vue'

import { DiscountType, DiscountTypeValues } from '@ankor-io/common/vessel/types'

import Button from '@/components/Button.vue'

export type DiscountProps = {
  discountType: DiscountType
  discountValue: string
}

const props = defineProps<DiscountProps>()
const emit = defineEmits<{
  (e: 'close:modal'): void
  (e: 'confirm:modal', value: DiscountProps): void
}>()

const discountType: Ref<DiscountType> = ref('PERCENTAGE')
const discountValue: Ref<string> = ref('')

onMounted(() => {
  discountType.value = props.discountType || DiscountTypeValues.PERCENTAGE
  discountValue.value =
    props.discountType === DiscountTypeValues.AMOUNT
      ? props.discountValue
      : (Math.round(Number(props.discountValue) * 100 * 100) / 100).toString()
})

const onConfirm = () => {
  emit('confirm:modal', {
    discountType: discountType.value,
    discountValue:
      discountType.value === DiscountTypeValues.AMOUNT
        ? (Math.round(Number(discountValue.value) * 100 * 100) / 100).toString()
        : discountValue.value,
  })
}
</script>
<template>
  <div class="h-auto w-auto flex flex-col print:hidden">
    <!-- Modal header -->
    <h3 class="text-lg text-left font-semibold text-gray-900 dark:text-white">Configure Discount:</h3>
    <!-- Modal body -->
    <div class="flex flex-col space-y-4 mt-4">
      <ul class="flex items-center text-xs font-medium space-x-4">
        <li>Discount Type:</li>
        <li>
          <input
            type="radio"
            id="percentage"
            name="discountType"
            v-model="discountType"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            value="PERCENTAGE"
          />
          <label for="percentage" class="py-3 ml-2 text-gray-600 dark:text-gray-300">PERCENTAGE</label>
        </li>
        <li>
          <input
            type="radio"
            id="amount"
            name="discountType"
            v-model="discountType"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            value="AMOUNT"
          />
          <label for="amount" class="py-3 ml-2 text-gray-600 dark:text-gray-300">AMOUNT</label>
        </li>
      </ul>

      <div class="w-80 relative">
        <input
          type="number"
          id="floating_outlined_discount_value"
          class="block w-full h-full rounded-lg border-1 border-gray-300 dark:border-gray-600 appearance-none focus:outline-none focus:ring-0 peer focus:border-primary-600 focus:text-primary-600 dark:focus:border-primary-500 dark:focus:text-primary-500 text-gray-900 dark:text-white bg-white dark:bg-gray-900"
          placeholder=" "
          v-model="discountValue"
        />
        <label
          for="floating_outlined_discount_name"
          class="absolute text-xs font-medium rounded-t text-gray-500 dark:text-gray-400 cursor-text duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-primary-600 dark:peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 bg-white dark:bg-gray-900"
        >
          Discount Value {{ discountType === DiscountTypeValues.PERCENTAGE ? '(%)' : '' }}
        </label>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="flex justify-end items-center space-x-2 rounded-b pt-5">
      <Button name="Cancel" :isPrimary="false" @click="emit('close:modal')"></Button>
      <Button name="Save" isPrimary @click="onConfirm" :disabled="discountValue === ''"></Button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { twMerge } from 'tailwind-merge'
import { Ref, computed, inject, onBeforeMount, ref, watch } from 'vue'
import draggable from 'vuedraggable'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import ImageCarouselModalEditor from '@ankor-io/blocks/components/ImageCarouselModal/ImageCarouselModalEditor.vue'
import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { JsonProposal } from '@ankor-io/common/proposal/Proposal'
import { MediaUriBuilder } from '@ankor-io/common/uri/uri.builder'
import { Pricing } from '@ankor-io/common/vessel/types'
import { OutlinePhoto } from '@ankor-io/icons/outline'
import { OutlineChevronDown } from '@ankor-io/icons/outline'
import { SolidCircleX, SolidDrag } from '@ankor-io/icons/solid'

import Spinner from '@/components/Spinner.vue'
import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import VesselSelection from '@/components/modal-content/VesselSelection.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import { AuthenticationContext } from '@/iam/types'
import { useModal } from '@/modal/useModal'
import PricingEditor from '@/sections/vessel-showcase/PricingEditor.vue'
import {
  Alignment,
  KeyValueFeatured,
  Size,
  VesselShowcaseLayoutTemplate,
  VesselShowcaseOptions,
  VesselShowcaseSectionData,
} from '@/sections/vessel-showcase/types/types'
import { linkMedia } from '@/services/MediaService'

type Props = {
  /**
   * The section id
   */
  id: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The vessel showcase data
   */
  data: VesselShowcaseSectionData
  /**
   * The vessel layout
   */
  layout: VesselShowcaseLayoutTemplate
  /**
   * The proposal document
   */
  document: JsonProposal
  isHydrating: boolean
  lifecycle: Runnable<EditableLifecycleHooks>
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<VesselShowcaseSectionData>): void
  (e: 'update:layout', value: { sectionId: string; layout: VesselShowcaseLayoutTemplate }): void
  (e: 'section:delete'): void
}>()

const { isOpen, updateModalState } = useModal()
const isModalOpen: Ref<boolean> = ref(false)
// const stowageService: StowageService = useStowageService()

const copyingAssets: Ref<boolean> = ref(false)
const showAllFeatures: Ref<boolean> = ref(false)
const isDragging: Ref<boolean> = ref(false)
const hoveredFeatureIndex: Ref<number> = ref(-1)
const showCarouselModal: Ref<boolean> = ref(false)
const authenticationContext: AuthenticationContext = inject('authenticationContext')!

// ref to keep track of features
const featuresRef = ref([] as KeyValueFeatured[])

props.lifecycle({
  onHydrated: async () => {
    if (props.data.images.length) {
      await linkMedia({ authenticationContext }, props.proposalUri, props.data.images)
    }
  },
} as unknown as EditableLifecycleHooks)

onBeforeMount(() => {
  featuresRef.value = props.data.features
})

watch(
  () => props.data.features,
  (newValue: KeyValueFeatured[]) => {
    if (JSON.stringify(featuresRef.value) !== JSON.stringify(newValue)) {
      featuresRef.value = newValue
    }
  },
)

watch(isOpen, (value) => {
  if (!value && isModalOpen.value) {
    onCancelSelection()
  }
})

const unwatch = watch(
  () => props.data.shouldCopyAssets, // not really copying but linking
  async (newValue: boolean) => {
    if (newValue && props.data.uri && props.proposalUri) {
      copyingAssets.value = false
      // link to proposal and unwatch
      await linkMedia({ authenticationContext }, props.proposalUri, props.data.images)
      unwatch()
    }
  },
)

const onConfirmSelection = (vesselUri: string | null) => {
  emit('update:value', {
    sectionId: props.id,
    data: {
      ...props.data,
      uri: vesselUri,
      shouldRehydrate: true,
    },
  })

  isModalOpen.value = false
  updateModalState(false)
}

const onCancelSelection = () => {
  emit('section:delete')
  updateModalState(false)
}

props.lifecycle({
  onBeforeHydrate: async () => {
    isModalOpen.value = true
    updateModalState(true)
  },
} as unknown as EditableLifecycleHooks)

const featuredItems = computed(() => {
  if (!featuresRef.value) {
    return []
  }
  return featuresRef.value.filter((feature) => feature.featured)
})

const updateValue = (dataKey: string, value: string | boolean | Pricing | KeyValueFeatured[] | null) => {
  emit('update:value', {
    sectionId: props.id,
    data: {
      ...props.data,
      [dataKey]: value,
    },
  })
}

const updateLayoutOptions = (options: VesselShowcaseOptions) => {
  emit('update:layout', {
    sectionId: props.id,
    layout: {
      ...props.layout,
      options,
    },
  })
}

const updateFeature = (featureIndex: number, dataKey: 'key' | 'value', value: string) => {
  const updatedFeatures: KeyValueFeatured[] = ObjectUtil.deepCopy(props.data.features)
  updatedFeatures[featureIndex][dataKey] = value
  updateValue('features', updatedFeatures)
}

const addNewFeature = () => {
  const updatedFeatures: KeyValueFeatured[] = ObjectUtil.deepCopy(props.data.features)
  updatedFeatures.push({ key: '', value: '', featured: false })
  updateValue('features', updatedFeatures)
}

const deleteFeature = (featureIndex: number) => {
  const updatedFeatures: KeyValueFeatured[] = ObjectUtil.deepCopy(props.data.features)
  updatedFeatures.splice(featureIndex, 1)
  updateValue('features', updatedFeatures)
}

/**
 * move position of a feature on change
 * @param e change event from draggable
 */
const updateOrder = (e: any): void => {
  if (e.moved) {
    updateValue('features', featuresRef.value)
  }
}

const getWrapperClasses = (size: Size = 'large', alignment: Alignment = 'center'): string => {
  let sizeClass = '@sm:w-3/4'
  if (size === 'small') {
    sizeClass = '@sm:w-1/2'
  }
  if (size === 'large') {
    sizeClass = 'w-full'
  }
  return twMerge('antialiased px-4 @sm:p-8 mx-auto', sizeClass, `text-${alignment}`)
}

const getMediaUrls = (images: string[] = []): string[] => {
  return images.map((image) =>
    image.startsWith('media::') ? image : new MediaUriBuilder().build(props.proposalUri, image),
  )
}
</script>
<template>
  <div v-if="props.isHydrating" class="flex items-center justify-center h-[70vh] @sm:h-[calc(100vh-7rem)]">
    <Spinner />
  </div>

  <!--Vessel showcase section-->
  <section v-else :class="getWrapperClasses(props.layout.options?.size, props.layout.options?.alignment)">
    <!-- Vessel name -->
    <h1 class="text-3xl @sm:text-6xl">
      <SingleLineTextEditor
        :value="props.data.name"
        placeholder="Vessel name"
        @update:value="updateValue('name', $event)"
      />
    </h1>

    <!-- Vessel type -->
    <h3>
      <SingleLineTextEditor
        :value="props.data.type"
        placeholder="Vessel Type"
        @update:value="updateValue('type', $event)"
      />
    </h3>

    <div
      class="flex"
      :class="[
        { 'justify-center': props.layout.options?.alignment === 'center' },
        { 'justify-end': props.layout.options?.alignment === 'right' },
      ]"
    >
      <hr class="border-t-2 my-1 border-theme-primary w-28" />
    </div>

    <!-- Featured Items -->
    <div v-if="featuredItems.length" class="relative z-10 ml-3 mr-3 mt-3 select-none">
      <dl
        class="inline-flex flex-wrap justify-center px-2 @sm:px-4 py-1 @sm:py-2 shadow-lg gap-2 @sm:gap-8 rounded-lg"
        :class="{ border: props.layout.options?.showHighlightsBorder }"
        :style="{ background: props.layout.options?.highlightsBackground }"
      >
        <div
          v-for="(featured, featuredIndex) of featuredItems"
          :key="`${featured.key}-${featuredIndex}`"
          class="flex flex-col justify-center items-center"
        >
          <dd class="text-xs @sm:text-sm font-normal opacity-60">
            <h3><SingleLineTextEditor :is-editable="false" :value="featured.key" /></h3>
          </dd>
          <dt class="-mt-1 text-sm @sm:text-2xl font-extrabold">
            <h3><SingleLineTextEditor :is-editable="false" :value="featured.value" /></h3>
          </dt>
        </div>
      </dl>
    </div>

    <!-- Vessel Gallery -->
    <div class="relative" :class="featuredItems.length ? '-mt-7' : 'mt-4'">
      <div v-if="copyingAssets" class="grow w-full h-96 items-center justify-center hidden @sm:flex">
        <Spinner />
      </div>
      <div v-else>
        <div class="h-[22.5rem] sm:h-full">
          <AssetViewerPlaceholder
            class="w-full overflow-hidden rounded-2xl col-span-2 object-cover"
            :url="`/media/${props.data.images?.[0]}`"
          />
        </div>

        <!-- Show all button -->
        <button
          v-if="props.data.images?.length"
          type="button"
          class="absolute bottom-8 right-8 sm:bottom-8 sm:right-8 z-10 text-gray-900 bg-white hover:bg-gray-100 border border-gray-900 focus:outline-none font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
          @click="showCarouselModal = true"
        >
          <OutlinePhoto class="inline-block w-4 h-4 stroke-2 mr-2" />
          Show all photos
        </button>
      </div>
    </div>

    <!-- Features -->
    <section v-if="props.layout.options?.showFeatures">
      <draggable
        item-key="id"
        class="grid gap-4 pt-2 mt-4 grid-cols-2 @sm:grid-cols-5 text-left transition-all duration-500 overflow-y-auto"
        ghostClass="asset-ghost"
        dragClass="asset-drag"
        :class="showAllFeatures ? 'max-h-[25rem] ease-in' : 'max-h-36 ease-out'"
        :list="featuresRef"
        @start="isDragging = true"
        @end="isDragging = false"
        @change="updateOrder"
      >
        <template #item="{ element: feature, index: featureIndex }: { element: KeyValueFeatured, index: number }">
          <div
            class="relative rounded-lg"
            :key="`${featureIndex}${feature.key}`"
            @mouseenter="hoveredFeatureIndex = featureIndex"
            @mouseleave="hoveredFeatureIndex = -1"
          >
            <SolidDrag
              v-if="hoveredFeatureIndex === featureIndex && !isDragging"
              class="absolute h-8 -left-6 top-[calc(50%-1rem)] fill-gray-500 cursor-grab"
            />

            <div
              class="h-full rounded-md hover:border-dashed border-theme-primary p-2 gap-x-2 gap-y-1 cursor-grab shadow-theme-shading text-center"
              :style="{ background: props.layout.options?.featuresBackground }"
            >
              <h2 class="mb-1 text-sm cursor-text opacity-6">
                <span v-if="isDragging" class="leading-[normal]">{{ feature.key || 'Add Feature' }}</span>
                <SingleLineTextEditor
                  v-else
                  placeholder="Add Feature"
                  :value="feature.key"
                  @update:value="updateFeature(featureIndex, 'key', $event)"
                />
              </h2>
              <div class="text-lg cursor-text">
                <span v-if="isDragging" class="leading-[normal]" v-html="feature.value || 'Feature value'"></span>
                <SingleLineTextEditor
                  v-else
                  placeholder="Feature value"
                  :value="feature.value"
                  :is-editable="!isDragging"
                  @update:value="updateFeature(featureIndex, 'value', $event)"
                />
              </div>
            </div>

            <div
              v-if="hoveredFeatureIndex === featureIndex && !isDragging"
              class="absolute z-10 -top-1 -right-1 cursor-pointer"
              @click="deleteFeature(featureIndex)"
            >
              <SolidCircleX class="w-6 h-6 fill-gray-500" />
            </div>
          </div>
        </template>
      </draggable>

      <!-- shading -->
      <div
        class="hidden crossfade-gradient pointer-events-none h-8 -mt-8 relative z-10"
        :class="showAllFeatures ? 'opacity-0' : 'opacity-100'"
      ></div>

      <!-- Feature actions -->
      <div class="flex justify-between items-center gap-x-3 mt-2 text-sm">
        <button
          class="mt-1 text-primary-700 border border-primary-600 bg-white hover:bg-primary-200 hover:primary-300 font-medium rounded-lg text-sm px-5 py-2.5"
          @click.stop="addNewFeature"
        >
          + Add Feature
        </button>

        <div class="flex items-center justify-end gap-x-1 cursor-pointer" @click="showAllFeatures = !showAllFeatures">
          Show
          <span>{{ showAllFeatures ? 'less' : 'more' }}</span>
          <OutlineChevronDown
            class="w-4 h-4 transition-all duration-700"
            :class="showAllFeatures ? 'rotate-180' : 'rotate-0'"
          />
        </div>
      </div>
    </section>

    <!-- Vessel description -->
    <p v-if="props.layout.options?.showDescription" class="mt-5">
      <MultiLineTextEditor
        placeholder="Enter vessel description here"
        :value="props.data?.description || ''"
        @update:value="updateValue('description', $event)"
      />
    </p>

    <!-- button-->
    <button
      v-if="props.layout.options?.showButton"
      class="text-white font-medium text-sm p-4 mt-4 rounded-lg max-w-full"
      :class="!props.data.buttonLink ? 'bg-gray-200 bg-opacity-25 shadow' : 'bg-theme-primary'"
    >
      <a target="_blank" :href="props.data.buttonLink || ''">
        {{ props.data.buttonText || 'Yacht Brochure' }}
      </a>
    </button>

    <!-- Price Offerings -->
    <PricingEditor
      v-if="props.data.pricing && props.layout.options?.showPricing"
      :pricing="props.data.pricing"
      :layoutOptions="props.layout.options"
      @update:pricing="updateValue('pricing', $event)"
      @update:layout:options="updateLayoutOptions"
    />

    <ModalContentWrapper v-if="isModalOpen">
      <VesselSelection @confirm:modal="onConfirmSelection($event)" />
    </ModalContentWrapper>
  </section>

  <Teleport v-if="showCarouselModal" to="body">
    <ImageCarouselModalEditor :slides="getMediaUrls(props.data.images)" @close="showCarouselModal = false" />
  </Teleport>
</template>

<style scoped>
.crossfade-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 15.04%,
    v-bind('props.document.template.theme.color.background') 82.09%
  );
}
</style>
